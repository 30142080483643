import {Card, CardContent, FormControl, InputAdornment, MenuItem, TextField, useMediaQuery} from "@mui/material";
import {useEffect, useState} from "react";
import Api, {postApi} from "../Api";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import {useSelector} from "react-redux";
import IconButton from "@mui/material/IconButton";
import {ArrowDownwardOutlined, ArrowUpwardOutlined, RestartAltOutlined} from "@mui/icons-material";

//eslint-disable-next-line
String.prototype.toHHMMSS = function () {
    var sec_num = parseInt(this, 10); // don't forget the second param
    var hours   = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);
    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}
    return hours+':'+minutes+':'+seconds;
};

function Duration(props){
    const [s, setS] = useState(props.sec);
    useEffect(()=>{
        let Durtimer = setInterval(()=>{setS((parseInt(s)+1).toString())}, 1000);
        return () => clearInterval(Durtimer);
    },[s])
    return <span>{s.toString().toHHMMSS()}</span>;
}


const ServerCard = props => {
    const [data, setData] = useState({info:{server_name:props.server, map_name:"Не отвечает"},players:[], con:["0",0]})
    const [maps, setMaps] = useState({maps:[]})
    const [smap, setSmap] = useState('')
    const permissions = useSelector(state => state.permissions.value);
    const mobile = useMediaQuery('(min-width:600px)')
    const getData = async () => {
        let data = await Api("monitoring/info/"+props.server)
        let l = Math.max(data.info.max_players,17)-data.players.length
        for (let ii = 0; ii < l; ii++) {
            data.players.push({name: ":::BLANKED:::", rank: ""});
        }
        setData(data)
    }
    useEffect(()=>{
        getData()
        let gdid = setInterval(getData, 5000);
        return () => clearInterval(gdid);
        //eslint-disable-next-line
    },[])

    const renderPlayerList = () => {
        return data.players.map((i, e) => {
            let color = "#fcffff";
            if (i["rank"] === "Главный администратор") color = "#ff0000";
            if (i["rank"] === "Зам. Гл. Администратора") color = "#d06969";
            if (i["rank"] === "Куратор") color = "#bb4a31";
            if (i["rank"] === "Заместитель куратора") color = "#d99789";
            if (i["rank"] === "Ст. Администратор") color = "#e87240";
            if (i["rank"] === "Администратор") color = "#ff9232";
            if (i["rank"] === "Мл. Администратор") color = "#faf309";
            if (i["rank"] === "Ст. Модератор") color = "#4482ef";
            if (i["rank"] === "Модератор") color = "#5380cd";
            if (i["rank"] === "Мл. Модератор") color = "#8dcff8";
            if (i["rank"] === "Хелпер") color = "#95f69a";
            if (i["rank"] === "Стажер") color = "#95f69a";
            if (i["name"] === ":::BLANKED:::"){
                return <div className={'playerclass'} title={i.rank} key={'player_' + e}>&nbsp;</div>
            }
            return <div className={'playerclass'} title={i.rank} key={'player_' + e} style={{color: color}}>
                {i.name || "(подключается)"} (<Duration sec={Math.round(i.duration)}/>)
            </div>
        })
    }

    const restartServer = () => {
        postApi('admin/rcon/restart', {server: props.server})
    }
    const mapsAction = async () => {
        if(maps.maps.length > 0){
            postApi('admin/rcon/changemap',{server: props.server, map: smap}).then(()=>{
                setMaps({maps:[]})
                setSmap('')
            })
        }
        else{
            setMaps(await Api(`admin/rcon/maps/${props.server}`))
        }
    }
    const handleChange = (e,c) => {
        setSmap(c.props.children)
    }
    return <Card>
        <CardContent sx={{fontSize: '9pt', height: '25rem'}}>
            <Grid container spacing={0}>
                <Grid item xs={5}>
                    <div><b>Сервер:</b> {data.info.server_name}</div>
                    <div><b>Версия:</b> {data.info.version} ({data.info.platform === "w" ? "Windows" :
                        (data.info.platform === "l" ? "Linux":"N/A")})</div>
                    <div><b>Карта:</b> <span style={{color: "yellow"}}>{data.info.map_name}</span></div>
                    <div><b>Игроков:</b> {data.info.player_count}/{data.info.max_players}</div>
                    {mobile && <Button style={{marginTop: 30}} variant={"contained"} color={"primary"}
                            href={"steam://connect/" + data.con[0] + ":" + data.con[1]}>Подключиться</Button>}
                    {permissions.indexOf('rcon_admin') > -1 && mobile &&
                        <div>
                            <IconButton sx={{mt:2}} onClick={restartServer} title={'Перезапустить сервер'}>
                                <RestartAltOutlined color={'error'}/>
                            </IconButton>
                            <br/>
                            <FormControl fullWidth>
                                <TextField
                                    label={'Смена карты'}
                                    select
                                    sx={{p:1, width: '90%'}}
                                    size={'small'}
                                    value={smap}
                                    SelectProps={{
                                        onChange: handleChange,
                                        size: 'small',
                                        sx: {fontSize: '9pt'}
                                    }}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">
                                            <IconButton
                                                edge="start"
                                                onClick={mapsAction}
                                            >
                                                {maps.maps.length > 0 ? <ArrowUpwardOutlined /> : <ArrowDownwardOutlined />}
                                            </IconButton>
                                        </InputAdornment>,
                                    }}
                                >
                                    {maps.maps.map((i,e)=><MenuItem key={'map_'+e} value={i}>{i}</MenuItem>)}
                                </TextField>
                            </FormControl>
                        </div>
                    }
                </Grid>
                <Grid item xs={7} style={{height: '25rem', overflowY: 'auto'}}>
                    {renderPlayerList()}
                </Grid>
            </Grid>
        </CardContent>
    </Card>
}

export default ServerCard